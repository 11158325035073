<template>
    <div class="forumSearchBox">
        <Search @search="search" />
        <div class="searchNum">已找到 {{searchList.length}} 个帖子</div>
        <div class="tabContentBox">
            <div class="tabContent">
                <div class="list" v-for="item in searchList" :key="item.id" @click="goDetail(item.id)">
                    <img :src="$util.host +item.cover" alt="" class="img" />
                    <div>
                        <p>{{item.name}}</p>
                        <p>
                            <span>发帖人：<font>{{item.sendUserName}}</font></span> <span>{{item.createTime}}</span>
                        </p>
                        <p v-html="item.content">
                        </p>
                        <p>
                            <span>评论：<font>{{item.commentNum}}</font></span>
                            <span>回复：<font>{{item.fabulousNum}}</font></span>
                        </p>
                    </div>
                </div>
                <!-- <div class="pageInput">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="100" layout="prev, pager, next , total , jumper" :total="1000" background=""></el-pagination>
                </div> -->
            </div>
        </div>

        <Footer />
    </div>
</template>
<script>
import Search from "../../components/forumSearch.vue";
import Footer from "../../components/footer.vue";
export default {
    components: { Search, Footer },
    name: "QppIndex",
    data() {
        return {
            activeName: "默认",
            active: 0,
            searchList: [],
            currentPage: 5,
            showClass: false,
            // form: {}
        };
    },
    mounted() {
        this.searchList = JSON.parse(this.$route.query.form);
        console.log(JSON.parse(this.$route.query.form));

    },
    methods: {
        navClickFun(id) {
            console.log(id);
            if (id == 7) {
                this.showClass = !this.showClass;
            } else if (id == 0) {
                this.$router.push({ path: "/understand" });
            }
        },
        search(val) {
            // this.form = val
            this.$util.post('/forum/search', val).then(res => {
                console.log(res);
                this.searchList = res.data
            })

        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        //查看详情
        goDetail(id) {
            this.$router.push({ path: "communityDetail", query: { id } });
        },
        changeTab(id) {
            this.active = id;
            console.log(this.active);
        },
    },
};
</script>
<style>
.activeClass {
    color: #e40012 !important;
    border-bottom: 2px solid #e40012;
}
</style>
<style lang="scss" scoped>
.forumSearchBox {
    .searchNum {
        width: 100%;
        height: 50px;
        background: rgba(228, 0, 18, 0.1);
        text-align: center;
        line-height: 50px;
        color: #e40012;
        font-size: 14px;
    }
    .titleData {
        width: 1140px;
        height: 60px;
        line-height: 60px;
        padding: 0 30px;
        margin: auto;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        .name {
            color: #222222;
            font-size: 24px;
        }
        .num {
            span {
                color: #999999;
                font-size: 16px;
                margin-left: 50px;
                font {
                    color: #222222;
                }
            }
            span:nth-child(3) {
                margin-left: 83px;
                color: #e40012;
                cursor: pointer;
            }
        }
    }
    .noneBox {
        position: absolute;
        width: 98px;
        border: 1px solid #d1d1d1;
        right: -30px;
        top: 130px;
        background: #ffffff;
        z-index: 999 !important;
        padding: 20px 0;
        p {
            font-size: 14px;
            padding-bottom: 15px;
            cursor: pointer;
        }
        p:last-child {
            padding-bottom: 0;
        }
    }
    .tabContentBox {
        width: 100%;
        background: #f6f6f6;
        .tabContent {
            width: 1200px;
            margin: auto;
            padding-top: 30px;
            .pageInput {
                display: flex;
                justify-content: center;
                padding-top: 38px;
            }
            .list {
                padding: 30px;
                height: 198px;
                background: #ffffff;
                cursor: pointer;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                div {
                    flex: 1;
                    height: 198px;
                    overflow: hidden;
                }
                p:nth-child(1) {
                    color: #222222;
                    font-size: 22px;
                    padding-bottom: 12px;
                }
                p:nth-child(2) {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 14px;
                    padding-bottom: 10px;
                    span:nth-child(3) {
                        color: #2ea7e0;
                    }
                    span {
                        color: #666666;
                        padding-right: 74px;
                    }
                    font {
                        color: #222222;
                    }
                }
                p:nth-child(3) {
                    font-size: 16px;
                    height: 84px;
                    margin-bottom: 15px;
                    overflow: hidden; /*溢出的部分隐藏*/
                    /deep/p {
                        overflow: hidden; /*溢出的部分隐藏*/
                        white-space: nowrap; /*文本不换行*/
                        text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
                    }
                    /deep/img {
                        display: none;
                    }
                }
                p:nth-child(4) {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                    span {
                        color: #666666;
                        padding-right: 34px;
                    }
                    font {
                        color: #222222;
                    }
                }
                .img {
                    width: 198px;
                    height: 198px;
                    margin-right: 20px;
                }
            }
        }
    }
    .communityContent {
        width: 1200px;
        margin: auto;
        .tableBox {
            margin-top: 50px;
            .tableList {
                display: flex;
                justify-content: flex-start;
                font-size: 22px;
                div {
                    margin-right: 63px;
                    cursor: pointer;
                    padding-bottom: 5px;
                    text-align: center;
                    width: 80px;
                    color: #666666;
                }
            }
        }
        .recommendBox {
            width: 1200px;
            height: 428px;
            .recommend {
                position: absolute;
                width: 100%;
                left: 0;
                height: 418px;
                background: #fff5f6;

                .fourList {
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                width: 128px;
                                height: 42px;
                                margin-bottom: 35px;
                                font-size: 16px;
                                color: #222222;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 120px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 120px;
                    }
                }

                .twoList {
                    padding: 50px 0;
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                width: 192px;
                                height: 62px;
                                margin-bottom: 45px;
                                font-size: 24px;
                                color: #222222;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 330px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 148px;
                    }
                }
            }
        }
        .bannerBox {
            margin-bottom: 50px;
            .bannerImg {
                width: 100%;
                height: 400px;
            }
        }
        .communityData {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
            .title {
                color: #222222;
                font-size: 24px;
            }
            .data {
                display: flex;
                justify-content: right;
                div {
                    font-size: 16px;
                    margin-left: 50px;
                    font {
                        color: #999999;
                    }
                    span {
                        color: #222222;
                    }
                }
            }
        }

        .img {
            height: 50px;
            cursor: pointer;
            padding-bottom: 18px;
        }

        .nav {
            padding-bottom: 50px;
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            position: relative;
            p {
                cursor: pointer;
            }
        }
    }
}
</style>
